export const routeMap: any = {
    Orders: 'orders',
    Order: 'orders',
    OrdersList: 'orders',
    AgentOrders: 'orders',
    AgentOrdersList: 'orders',
    OrderInfo: 'orders',
    OrderCreate: 'orders/create',
    InternalOrder: 'orders/internal-create',
    OrderTypes: 'orders/types',
    OrderSubtypes: 'orders/subtypes',
    ProductionsProductComponents: 'production-components',
    Productions: 'production',
    OrdersProducts: 'orders-products',
    Consumers: 'consumers',
    Pos: 'pos',
    ProductionProducts: 'production/products',
    ProductionTypes: 'production/types',
    Production: 'production',
    ProductionOptions: 'production',
    OrderShipments: 'orders/:orderId/shipments',
    OrderShipment: 'orders/:orderId/shipments',
    OrderProducts: 'orders/:orderId/products',
    OrderCosts: 'orders/:orderId/costs',
    OrderDocuments: 'orders/:orderId/documents',
    OrderDocumentsCreate: 'orders/:orderId/documents/create',
    OrderDocumentsEdit: 'orders/:orderId/documents/edit/:invoiceId',
    OrderPayments: 'orders/:orderId/payments',
    OrderProposalsProducts: 'order-proposals/products',
    OrderProposals: 'order-proposals',
    ProductionCopy: 'production/copy',
    Shipments: 'shipments',
    Shipment: 'shipments',
    ShipmentsList: 'shipments',
    ShipmentDelayTypes: 'shipments/types',
    OverdueShipments: 'shipments/overdue',
    Events: 'events',
    Event: 'events',
    OperationsParameters: 'events/parameters',
    ResourceBookings: 'events/resource-bookings',
    Calendar: 'events/calendar',
    CalendarSettings: 'events/calendar/settings',
    CalendarsList: 'events/calendar/settings',
    CalendarOverview: 'events/calendar/overview',
    CalendarProduction: 'events/calendar/production',
    Boards: 'events/boards',
    Board: 'events/boards',
    TimeOptions: 'time-options',
    EmployeesDocuments: 'employees/documents',
    DefectActs: 'employees/documents/defect-acts',
    Employees: 'employees',
    Employee: 'employees',
    EmployeeOptions: 'employees',
    EmployeeSchedule: 'employees/:employeeId/schedule',
    Salary: 'employees/:employeeId/salary',
    EmployeeOperations: 'employees/:employeeId/operations',
    StaffOptions: 'staff-options',
    EmployeesJobsList: 'jobs',
    ProductContainers: 'products/containers',
    ProductContainer: 'products/containers',
    Products: 'products',
    ProductsContainers: 'products/containers',
    Product: 'products',
    ProductsEvents: 'products/events',
    ProductsLogs: 'products/logs',
    ProductOrders: 'products/:productId/orders',
    ProductTests: 'products/:productId/tests',
    ProductsMinQuantityList: 'products/min-quantity',
    ProductsWeight: 'products/products-weight',
    AgentInvoices: 'accounting/invoices',
    Accounting: 'accounting/invoices',
    AccountingCategories: 'accounting/categories',
    Invoices: 'accounting/invoices',
    InvoicesCreate: 'accounting/invoices/create',
    InvoicesEdit: 'accounting/invoices/edit',
    Invoice: 'accounting/invoices/edit',
    Reports: 'accounting/accounting-reports',
    InvoicesProducts: 'accounting/invoices/products',
    GeneralLedger: 'accounting/general-ledger',
    AccountProps: 'accounting/props',
    AccountingPayments: 'accounting/payments',
    AccountingPaymentCoupons: 'accounting/payments/coupons',
    AccountingBooks: 'accounting/books',
    Balance: 'accounting/balance',
    AccountingAssetProducts: 'assets/products',
    AccountingAssetAmortization: 'assets/amortization',
    AccountingAssets: 'assets',
    AccountingAssetTypes: 'assets/types',
    AssetsPurchase: 'assets-purchase',
    BankTransactions: 'accounting/banks/transactions',
    CashRegisters: 'accounting/cash-registers',
    ReportsSalesDaily: 'accounting/cash-registers',
    CustomerClaim: 'accounting/claims',
    CustomerClaims: 'accounting/claims',
    CustomerClaimTypes: 'accounting/claims/types',
    CustomerClaimQuestions: 'accounting/claims/questions',
    CrmCountries: 'countries',
    ArticlesExposure: 'articles-exposure',
    ArticlesExposurePresentations: 'articles-exposure/presentations',
    Articles: 'articles',
    ArticlesTypes: 'articles/types',
    ArticlesType: 'articles/types',
    ArticlesParameters: 'articles/parameters',
    ArticlesOperations: 'articles/operations',
    ArticlesOperationsCommentTypes: 'articles/operations/comment-types',
    Article: 'articles',
    ArticleInfo: 'articles',
    ArticleOperations: 'articles/:articleId/operations',
    ArticleOperation: 'articles/:articleId/operations',
    ArticleHistory: 'article-history',
    ArticleTestTypes: 'articles/article-test-types',
    Components: 'components',
    Component: 'components',
    Features: 'features',
    Feature: 'features',
    ArticleCategories: 'article-categories',
    ArticleCategory: 'article-categories',
    ArticlesTests: 'articles-tests',
    ArticlesTest: 'articles-tests',
    ArticlesSorting: 'articles/sort',
    MaterialsMinQuantityList: 'materials/min-quantity',
    MaterialsOrderReservations: 'materials-reservations',
    MaterialsReservations: 'materials-reservations',
    MaterialsOutOfStockSuppliers: 'materials-reservations/out-of-stock',
    MaterialsOutOfStockSupplier: 'materials-reservations/out-of-stock',
    MaterialsTests: 'materials-tests',
    Materials: 'materials',
    Material: 'materials',
    MaterialOptions: 'materials',
    MaterialsSorting: 'materials/sort',
    MaterialTypes: 'material-types',
    MaterialType: 'material-types',
    MaterialTypesParameters: 'material-types/parameters',
    MaterialCategories: 'material-categories',
    MaterialCategory: 'material-categories',
    MaterialsFormulas: 'formulas',
    MaterialQuants: 'material-quants',
    MaterialQuantStock: 'materials/stock',
    ScalesWizard: 'formulas/mix',
    FormulasList: 'formulas',
    Formula: 'formulas',
    MaterialRemnantsMovementList: 'remnants-movement',
    MaterialRemnantsMovement: 'materials/:materialId/remnants-movement',
    Purchase: 'purchase',
    PurchaseCategories: 'purchase/categories',
    PurchaseTemplates: 'purchase/templates',
    PurchaseBySupplierProduct: 'purchase/:purchaseId/by-supplier-product',
    PurchaseBySupplier: 'purchase/:purchaseId/by-supplier',
    InventoryCategories: 'inventory-categories',
    InventoryTests: 'inventory-tests',
    InventoryTags: 'inventory-tags',
    InventoryCategory: 'inventory-categories',
    InventoryTypes: 'inventory-types',
    InventoryType: 'inventory-types',
    Inventors: 'inventory',
    Inventor: 'inventory',
    Inventory: 'inventory',
    InventoryMinQuantityList: 'inventory/min-quantity',
    InventoryPurchase: 'inventory-purchase',
    Inventorization: 'inventorization',
    InventorizationByDate: 'inventorization-by-date',
    Taxes: 'taxes',
    TaxTypes: 'tax-types',
    LedgerTypes: 'ledger-types',
    SalaryTaxes: 'salary-taxes',
    SupplierEvaluation: 'suppliers/evaluation',
    SupplierDelayedArrivals: 'suppliers/delayed-arrivals',
    SupplierDebts: 'suppliers/debts',
    Suppliers: 'suppliers',
    Supplier: 'suppliers',
    SupplierOptions: 'suppliers',
    Expressions: 'functions',
    Expression: 'functions',
    Agents: 'agents',
    Agent: 'agents',
    AgentsPermissions: 'agents/agents-permissions',
    AgentsTree: 'agents/tree',
    SaleEventsList: 'sale-events',
    SaleEvents: 'sales-events',
    SaleEvent: 'sales-events',
    SaleEventsTypes: 'sales-events/types',
    SaleEventStages: 'sales-events/stages',
    Carriers: 'carriers',
    Carrier: 'carriers',
    CarrierEmployees: 'carriers/:carrierId/employees',
    CarrierEmployee: 'carriers/:carrierId/employees',
    Customers: 'customers',
    CustomersList: 'customers',
    Customer: 'customers',
    CustomersPermissions: 'customers/customers-permissions',
    CustomerTypes: 'customers/customer-types',
    CustomerType: 'customers/customer-types',
    CrmDepartments: 'customers/departments',
    CustomersEmployeesUsers: 'customers/employees-users',
    CustomersEmployees: 'customers/customer-employees',
    CustomersCards: 'customers/cards',
    CustomersCertificates: 'customers/certificates',
    CustomerEmployeesList: 'customers/:customerId/employees',
    CustomerEmployee: 'customers/:customerId/employees',
    CustomerMeetingsList: 'customers/:customerId/meetings',
    CustomerMeeting: 'customers/:customerId/meetings',
    CustomerSaleOpportunities: 'customers/:customerId/sales-opportunities',
    CustomerSaleOpportunity: 'customers/:customerId/sales-opportunities',
    CustomerLimitedArticles: 'customers/:customerId/limited-articles',
    CustomerLimitedTemplates: 'customers/:customerId/limited-templates',
    DevelopmentProjects: 'dev-projects',
    DevelopmentProjectsList: 'dev-projects',
    DevelopmentProject: 'dev-projects',
    DevelopmentProjectStages: 'dev-projects/stages',
    Roles: 'roles',
    Role: 'roles',
    RoleSpecialisations: 'roles/:roleId/specialisations',
    RoleSpecialisationsList: 'roles/:roleId/specialisations',
    RoleSpecialisation: 'roles/:roleId/specialisations',
    MaintenanceList: 'maintenance',
    Maintenance: 'maintenance',
    MaintenanceRecord: 'maintenance',
    ResourcesList: 'resources',
    Workplaces: 'resources/workplaces',
    Workplace: 'resources/workplaces',
    Workgroups: 'resources/workgroups',
    Workgroup: 'resources/workgroups',
    WorkplaceTools: 'resources/workplaces/:workplaceId/tools',
    WorkplaceTool: 'resources/workplaces/:workplaceId/tools',
    WorkplaceArticles: 'resources/workplaces/:workplaceId/articles',
    WorkplaceDefectActs: 'resources/workplaces/:workplaceId/defect_acts',
    WorkplaceMaintenance: 'resources/workplaces',
    Operations: 'resources/operations',
    Operation: 'resources/operations',
    WorkplacesInfo: 'workplaces',
    Subcontractors: 'subcontractors',
    SubcontractorsList: 'subcontractors',
    Subcontractor: 'subcontractors',
    SubcontractorOptions: 'subcontractors',
    SubcontractorParametersList: 'subcontractors/:subcontractorId/parameters',
    SubcontractorParameter: 'subcontractors/:subcontractorId/parameters',
    SubcontractorEmployees: 'subcontractors/:subcontractorId/employees',
    SubcontractorEmployee: 'subcontractors/:subcontractorId/employees',
    SystemSettingsCommon: 'system-settings/common-settings',
    SystemSettingsTemplates: 'system-settings/templates',
    SystemSettingsTemplatesEdit: 'system-settings/templates',
    SystemSettingsTags: 'system-settings/tags',
    SystemSettingsTimeTags: 'system-settings/time-tags',
    SystemSettingsDiscounts: 'system-settings/discounts',
    SystemSettingsDiscountTags: 'system-settings/discounts/tags',
    OrdersPriorityLevels: 'system-settings/priorities',
    LooseRelationTypes: 'system-settings/loose-relations',
    Translations: 'system-settings/translations',
    SystemSettingsEventsLog: 'system-settings/events-log',
    DynamicFields: 'system-settings/dynamic-fields',
    SystemSettingsDevices: 'system-settings/devices',
    SystemSettingsLoyaltyCards: 'system-settings/loyalty-cards',
    Documentation: 'documentation',
    BarcodePrinter: 'system-settings/devices/printers',
    DynamicQueries: 'dynamic-tables/dynamic-queries',
    DynamicQuery: 'dynamic-tables/dynamic-queries',
    PersonalDynamicTables: 'dynamic-tables/personal',
    PersonalDynamicTable: 'dynamic-tables/personal',
    DynamicTablesCharts: 'dynamic-tables/charts',
    DynamicTablesChart: 'dynamic-tables/charts',
    DynamicTablesAggregatedTables: 'dynamic-tables/aggregated-tables',
    DynamicTablesAggregatedTable: 'dynamic-tables/aggregated-tables',
    DynamicTables: 'dynamic-tables',
    DynamicTable: 'dynamic-tables',
    SalesReports: 'reports/sales',
    MaterialsReports: 'reports/materials',
    ProductionReports: 'reports/production',
    LoadingReports: 'reports/loading',
    EntranceReport: 'reports/entrance',
    UserOptions: 'options',
    UserNotifications: 'options/notifications',
    SaleOpportunities: 'sales-opportunities',
    SalesOpportunities: 'sales-opportunities',
    SalesOpportunitiesListView: 'sales-opportunities/all',
    SaleOpportunity: 'sales-opportunities',
    SaleOpportunityStages: 'sales-opportunities/stages',
    SaleOpportunityTypes: 'sales-opportunities/types',
    SaleOpportunityProposals: 'sales-opportunities/:saleOpportunityId/proposals',
    Parameters: 'parameters',
    Parameter: 'parameters',
    ParameterOptions: 'parameters',
    Cameras: 'cameras',
    CamerasSettings: 'system-settings/devices/cameras',
    CamerasSettingsCameraEdit: 'system-settings/devices/cameras',
    Dimensions: 'dimensions',
    Tabel: 'tabel',
    Actions: 'notifications',
    ActionsList: 'notifications',
    TableListener: 'notifications/objects',
    PeriodicListener: 'notifications/periodic',
    ButtonListener: 'notifications/button',
    MailListener: 'notifications/mail',
    SubordinationTree: 'subordination',
    PermissionsMap: 'permissions',
    PermissionsMenu: 'permissions/menu',
    ClientsPortal: 'customers/clients-portal',
    ShopOptions: 'customers/shop-options',
    OrderProductionsCreate: 'orders/:orderId/production/create-production',
    OrderProductions: 'orders/:orderId/production',
    WorkShifts: 'time-options/shifts',
    WorkdayExceptionReasons: 'time-options/reasons',
    CorpTables: 'corp/tables',
    SystemSettingsMenu: 'system-settings/menu',
    Plan: 'workplaces/map',
    Plans: 'workplaces/map',
    Services: 'services',
    ServicePurchase: 'service-purchase',
    Trips: 'trips',
    Trip: 'trips',
    Files: 'files',
};
